@use 'sass:map';
@use '@angular/material';

@mixin app-grouped-list-item($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);

  .app-grouped-list-container {
    display: block;
  }

  .app-grouped-list-item {
    height: 40px;
    padding: 0.25rem 0;
    border-radius: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: material.m2-get-color-from-palette($background, app-hover);
    }
    &.active {
      background-color: material.m2-get-color-from-palette($background, app-card-background);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
