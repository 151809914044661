@use 'sass:map';
@use '@angular/material';

@mixin app-class-level-picker($theme) {
  $typography: material.m2-get-typography-config($theme);

  .app-class-level-picker {
    @include material.elevation(1);
    @include material.m2-typography-level($typography, 'headline-6');
    display: inline-flex;
    align-items: center;
    border-radius: 1rem;
    .app-class-level-picker-value {
      min-width: 32px;
      text-align: center;
      user-select: none;
    }
    .mat-mdc-button {
      min-width: 48px;
      height: 48px;
      &:first-of-type {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
      }
      &:last-of-type {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
    }
  }
}
