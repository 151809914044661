@use 'sass:map';
@use '@angular/material';
@use 'src/scss/material/app-screen';

@mixin app-profile-card($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);
  $foreground: map.get($color, foreground);
  $image-size: 140px;

  .app-profile-card {
    position: relative;
    max-width: 450px;
    padding: calc(0.5rem + 2 * $image-size / 3) 1rem 1rem 1rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc($image-size / 3);
    border-radius: 1rem;
    color: material.m2-get-color-from-palette($foreground, text);
    background-color: material.m2-get-color-from-palette($background, app-card-background);

    .app-profile-card-image-container {
      position: absolute;
      top: calc(-1 * $image-size / 3);
      left: calc(50% - $image-size / 2);

      display: flex;
      justify-content: center;

      .app-profile-card-image-hexagon,
      .app-profile-card-image {
        width: $image-size;
        height: $image-size;
      }
    }

    .app-profile-card-more {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  .app-profile-achievements {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    @include app-screen.screen-lg() {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
