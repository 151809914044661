@use 'sass:map';
@use '@angular/material';
@use 'src/scss/material/app-screen';

@mixin app-achievement($theme) {
  $color: material.m2-get-color-config($theme);
  $accent: map.get($color, accent);
  $background: map.get($color, background);
  $foreground: map.get($color, foreground);

  .app-achievement-preview {
    $image-size: 96px;
    position: relative;
    cursor: pointer;

    .app-achievement-preview-container {
      position: relative;
      height: calc(100% - $image-size / 2);
      padding: calc(0.5rem + $image-size / 2) 1rem 1rem 1rem;
      margin-top: calc($image-size / 2);
      border-radius: 1rem;
      overflow: hidden;
      background-color: material.m2-get-color-from-palette($background, app-achievement-background-card);
    }

    .app-achievement-preview-image-container {
      z-index: 10;
      position: absolute;
      top: 0;
      left: calc(50% - $image-size / 2);

      display: flex;
      justify-content: center;

      .app-achievement-preview-image {
        width: $image-size;
        height: $image-size;
      }
    }

    .app-achievement-preview-progress-bar {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      background-color: material.m2-get-color-from-palette($accent, 400);
    }
  }

  .app-achievement-card {
    $image-size: 300px;

    display: block;
    position: relative;
    padding: 1rem;
    background-color: material.m2-get-color-from-palette($background, app-background);
    border-radius: 1rem;
    overflow: auto;

    .app-achievement-card-image-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .app-achievement-card-image {
        width: $image-size;
        height: $image-size;
        &.app-achievement-card-image-achieved {
          filter: drop-shadow(0 0 0.25rem material.m2-get-color-from-palette($background, app-image-shadow));
        }
      }
    }

    .app-achievement-card-actions {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    .app-highlighted-span.app-achievement-badge {
      background-color: material.m2-get-color-from-palette($background, app-achievement-badge);
      color: #000000;
      text-transform: uppercase;
      font-size: 10px;
      height: 16px;
    }

    .app-achievement-card-progress-bar {
      position: relative;
      margin-bottom: 0.5rem;
      height: 4px;
      width: 100%;
      background-color: material.m2-get-color-from-palette($background, app-progress-background);
      border-radius: 1rem;
      overflow: hidden;

      .app-achievement-card-progress-bar-fill {
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        background-color: material.m2-get-color-from-palette($accent, 400);
      }
    }
  }
}
