@use 'sass:map';
@use '@angular/material';

@mixin app-tooltip($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);

  .app-tooltip {
    @include material.elevation(2);
    width: 100%;
    overflow: auto;
    padding: 0.5rem;
    background-color: material.m2-get-color-from-palette($background, tooltip, 0.95);
    color: #ffffff;
    border-radius: 0.25rem;

    .app-markdown {
      table {
        thead {
          tr {
            background-color: material.m2-get-color-from-palette($background, app-markdown-table-header-row-background);
          }
        }

        tbody {
          tr:nth-of-type(odd) {
            background-color: material.m2-get-color-from-palette($background, app-markdown-table-row-odd-background);
          }
          tr:nth-of-type(even) {
            background-color: material.m2-get-color-from-palette($background, app-markdown-table-row-even-background);
          }
        }
      }
    }
  }
}
