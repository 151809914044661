@use 'sass:map';
@use '@angular/material';
@use 'src/scss/material/app-screen';

@mixin app-page($theme) {
  .app-page {
    // max width to be consistent with bootstrap container
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 1rem 1rem 0 1rem;
    @include app-screen.screen-lg() {
      padding-top: 2rem;
    }

    &.app-page-center {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .app-page-listing {
    height: 100%;
    overflow: hidden;
  }

  .app-page-tiles-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include app-screen.screen-md() {
      grid-template-columns: repeat(3, 1fr);
    }
    @include app-screen.screen-lg() {
      grid-template-columns: repeat(4, 1fr);
    }
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  .app-page-card {
    display: block;
    width: 100%;
    height: 100%;

    .mat-mdc-tab-nav-panel {
      height: calc(100% - 48px);
      display: block;
      padding-top: 1rem;
    }

    .app-card {
      width: 100%;
      height: 100%;
    }
  }

  .page-layout {
    display: grid;
    grid-template-columns: 100%;
    @include app-screen.screen-lg() {
      grid-template-columns: 50% 50%;
    }
    grid-template-rows: auto 1fr;
    height: 100%;

    .page-layout-filter {
      grid-column: 1 / 3;
      @include app-screen.screen-sm() {
        grid-column: 1 / 2;
      }
    }
    .page-layout-add-area {
      grid-column: 2 / 3;
      @include app-screen.screen-xs-only() {
        display: none;
      }
      .page-layout-add-area-button {
        margin-top: 0.7rem;
      }
    }

    .page-layout-list {
      grid-column: 1 / 3;
      overflow-y: scroll;
      overflow-x: hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      /* Hide scrollbar for IE, Edge and Firefox */
    }

    &.details-open {
      .page-layout-list {
        grid-column: 1 / 3;
        @include app-screen.screen-sm() {
          grid-column: 1 / 2;
        }
      }
      .page-layout-details {
        height: 100%;
        overflow: hidden;
        grid-row: 1 / 3;
        grid-column: 2 / 3;

        @include app-screen.screen-xs-only() {
          grid-column: 1 / 3;
          position: fixed;
          height: calc(100% - 54px);
          top: 54px;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}
