@use 'sass:map';
@use '@angular/material';

@mixin app-filter($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);

  .app-filter {
    .app-form-field {
      @include material.elevation(2);
      border-radius: 1rem;
      .mat-mdc-form-field .mat-mdc-text-field-wrapper {
        background-color: material.m2-get-color-from-palette($background, app-card-background);
      }
    }
    z-index: 1000;
    display: block;
    user-select: none;

    .app-form-field .mat-mdc-form-field .mdc-text-field--filled {
      --mdc-filled-text-field-container-shape: 1rem;
    }
  }

  .app-filter-button {
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
  }

  .app-filter-button.inactive {
    opacity: 0.5;
  }
}
