@use 'sass:map';
@use '@angular/material';

@mixin app-checkbox($theme) {
  $color: material.m2-get-color-config($theme);
  $primary: map.get($color, primary);
  $accent: map.get($color, accent);
  $warn: map.get($color, warn);

  .app-checkbox {
    &.app-checkbox-raised {
      .mat-mdc-text-field-wrapper {
        position: relative;

        .mat-mdc-form-field-focus-overlay {
          opacity: 0.04;
          border-radius: 1rem;
        }
      }
    }

    .mat-mdc-checkbox {
      width: 100%;
      padding: 0 0.5rem;
      .mdc-form-field {
        width: 100%;

        .mdc-label {
          width: 100%;
          line-height: 56px;
          color: var(--mdc-filled-text-field-label-text-color);
          font-family: var(--mdc-filled-text-field-label-text-font);
          font-size: var(--mdc-filled-text-field-label-text-size);
          font-weight: var(--mdc-filled-text-field-label-text-weight);
          letter-spacing: var(--mdc-filled-text-field-label-text-tracking);
        }
      }
    }

    &.app-form-field-text-highlighted
      .mat-mdc-text-field-wrapper
      .mat-mdc-checkbox.mat-mdc-checkbox-checked
      .mdc-form-field
      .mdc-label {
      color: var(--mat-option-selected-state-label-text-color);
    }
  }

  .app-checkbox-multi {
    padding: 0.75rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    .mat-icon {
      flex: 0 0 24px;
    }

    .app-label {
      margin-left: 0.5rem;
    }

    &.app-checkbox-multi-checked,
    &.app-checkbox-multi-unchecked {
      .app-label {
        color: var(--mat-option-selected-state-label-text-color);
      }
    }
  }
}
