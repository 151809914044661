@use 'sass:map';
@use '@angular/material';

@mixin mat-tab-group($theme) {
  .mat-mdc-tab-group {
    .mat-mdc-tab {
      $active: #ffffff;
      $inactive: initial;
      $ripple: transparent;
      z-index: 1;

      // active
      --mat-tab-header-active-label-text-color: #{$active};
      --mat-tab-header-active-focus-label-text-color: #{$active};
      --mat-tab-header-active-hover-label-text-color: #{$active};
      // inactive
      --mat-tab-header-inactive-label-text-color: #{$inactive};
      --mat-tab-header-inactive-focus-label-text-color: #{$inactive};
      --mat-tab-header-inactive-hover-label-text-color: #{$inactive};
      // ripple
      --mat-tab-header-disabled-ripple-color: #{$ripple};
      --mat-tab-header-active-ripple-color: #{$ripple};
      --mat-tab-header-inactive-ripple-color: #{$ripple};

      // tab states
      &:not(.mat-mdc-tab-disabled) {
        opacity: 0.8;
        z-index: 2;
      }
      &.mdc-tab--active:not(.mat-mdc-tab-disabled) {
        opacity: 1;
        z-index: 1;
      }
      &.mat-mdc-tab-disabled {
        opacity: 0.5;
      }

      .mdc-tab__content {
        width: 100%;

        .mdc-tab__text-label {
          width: 100%;
          display: flex;
          justify-content: center;

          .app-mat-tab-indicators {
            position: absolute;
            bottom: 11px;
            display: flex;
            justify-content: center;
            width: 100%;
            z-index: -1;
          }
        }
      }

      // The inc bar becomes floatable background
      .mdc-tab-indicator__content {
        background: var(--mdc-tab-indicator-active-indicator-color);
        border-radius: 1rem;
        height: calc(100% - 1rem);
        margin: 0.5rem 1rem;
        border: none;
      }
    }
  }
}
