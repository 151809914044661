@use 'sass:map';
@use '@angular/material';
@use 'src/scss/material/app-screen';

@mixin app-login($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);
  $foreground: map.get($color, foreground);

  .app-login {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.5rem;
    overflow: auto;
    position: relative;

    .background-image {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: material.m2-get-color-from-palette($background, app-background);
      background-image: url(/assets/login-background.webp);
      background-repeat: repeat;
      background-position: center;
      background-size: cover;
      filter: blur(4px);
      z-index: -1;
    }

    .wrapper {
      display: flex;
      flex-wrap: wrap;

      .left-panel {
        flex: 0 0 45%;
      }

      .right-panel {
        flex: 0 0 100%;
        @include app-screen.screen-sm() {
          flex: 0 0 35%;
        }
      }
    }

    .site-name {
      display: flex;
      align-items: center;
      font-weight: 500;
      margin-bottom: 1.5rem;

      .site-name-dash {
        width: 30px;
        height: 5px;
        background-color: material.m2-get-color-from-palette($foreground, text);
        margin-right: 0.5rem;
      }
    }

    .form-col {
      width: 350px;
    }

    .form {
      $logo-size: 94px;
      position: relative;
      border: 1px solid #f63c0329;
      background-color: material.m2-get-color-from-palette($background, app-card-background);
      border-radius: 1rem;
      padding: 52px;
      padding-bottom: 1rem;
      @include app-screen.screen-xs-only() {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .logo-container {
        position: absolute;
        top: calc(-1 * $logo-size / 2);
        left: calc(50% - $logo-size / 2);

        .logo-img {
          width: $logo-size;
          height: $logo-size;
        }
      }
    }

    .submit-button {
      width: 100%;
      border-radius: 2rem;
    }

    .nav-link {
      display: block;
      margin-top: 1rem;
      text-align: center;
      text-decoration: none;
      color: inherit;
      font-weight: 500;
    }

    .app-version {
      position: absolute;
      bottom: 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .app-auth-modal {
    display: block;
    @include app-screen.screen-sm() {
      min-width: 450px;
    }

    .form {
      $logo-size: 94px;
      position: relative;
      border: 1px solid #f63c0329;
      background-color: material.m2-get-color-from-palette($background, app-card-background);
      border-radius: 1rem;
      padding: 1rem;
      @include app-screen.screen-sm() {
        padding: 2rem 4rem 4rem 4rem;
      }

      .logo-container {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        .logo-img {
          width: $logo-size;
          height: $logo-size;
        }
      }
    }

    .app-checkbox .mat-mdc-checkbox .mdc-form-field .mdc-label {
      line-height: normal;
    }

    .nav-link {
      display: block;
      margin-top: 1rem;
      text-align: center;
      text-decoration: none;
      color: inherit;
      font-weight: 500;
    }

    .submit-button {
      width: 100%;
      border-radius: 2rem;
    }
  }

  .mat-bottom-sheet-container .app-auth-modal .form {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
