@use 'sass:map';
@use '@angular/material';

@mixin app-background-card($theme) {
  // Установить ширину первого столбца таблицы следующей за .app-background-list в 60px,
  // Этот столбец всегда обозначает кубы, например d6
  .app-background-list + table {
    tr {
      th:first-of-type,
      td:first-of-type {
        width: 60px;
      }
    }
  }
}
