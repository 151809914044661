@use 'sass:map';
@use '@angular/material';

@mixin app-markdown($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);

  .app-markdown {
    display: block;
    width: auto;
    overflow: auto;
    table {
      width: 100%;
      margin-bottom: 0.75rem;

      thead {
        tr {
          background-color: material.m2-get-color-from-palette($background, app-markdown-table-header-row-background);
        }
        th {
          min-width: 40px;
          padding: 0.125rem 0.25rem;
          height: 40px;
        }
      }

      tbody {
        tr:nth-of-type(odd) {
          background-color: material.m2-get-color-from-palette($background, app-markdown-table-row-odd-background);
        }
        tr:nth-of-type(even) {
          background-color: material.m2-get-color-from-palette($background, app-markdown-table-row-even-background);
        }

        td {
          padding: 0.125rem 0.25rem;
          min-height: 40px;
        }
      }
    }

    blockquote {
      background-color: material.m2-get-color-from-palette($background, app-markdown-blockquote-background);
      border-left: 10px solid #ccc;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      margin: 0.5rem;
      padding: 0.5rem;
      & p:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
