@use 'sass:map';
@use '@angular/material';

@mixin app-view-card($theme) {
  .app-view-card {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    .app-card {
      width: 100%;
      height: 100%;
    }

    .app-effect-divider {
      height: 100%;
      --mat-divider-color: currentColor;
      --mat-divider-width: 2px;
    }

    .high-level-dice {
      display: inline-flex;
      align-items: center;

      .mat-icon {
        width: 1rem;
        min-width: 1rem;
        height: 1rem;
        font-size: 1rem;
      }
    }

    .app-content-license-links {
      flex: 1 0 100%;
      margin-top: 0.5rem;
    }
  }
}
