@use 'sass:map';
@use '@angular/material';

@mixin app-simple-card($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);

  .app-simple-card {
    display: flex;
    align-items: center;
    min-height: 65px;
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: material.m2-get-color-from-palette($background, app-card-background);

    & > :first-child {
      flex: 1 1 calc(100% - 40px);
      overflow: hidden;
    }

    & > :last-child {
      flex: 0 0 40px;
    }
  }
}
