@use 'sass:map';
@use '@angular/material';

@mixin app-navbar($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);

  .app-navbar {
    @include material.elevation(2);
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: material.m2-get-color-from-palette($background, app-card-background);
    border-radius: 1rem;
    margin-bottom: 0.125rem;
    overflow: auto;

    .app-navbar-button.mat-mdc-button-base {
      border-radius: 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
