@use 'sass:map';
@use '@angular/material';

@mixin app-notification($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);

  .app-notification {
    display: flex;
    align-items: center;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    min-height: 48px;

    &.info {
      background-color: material.m2-get-color-from-palette($background, app-notification-info-background);
      color: material.m2-get-color-from-palette($background, app-notification-info-text);
    }
    &.warning {
      background-color: material.m2-get-color-from-palette($background, app-notification-warning-background);
      color: material.m2-get-color-from-palette($background, app-notification-warning-text);
    }
    &.error {
      background-color: material.m2-get-color-from-palette($background, app-notification-error-background);
      color: material.m2-get-color-from-palette($background, app-notification-error-text);
    }
  }
}
