@use 'sass:map';
@use '@angular/material';

@mixin app-ability-card($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);

  .app-ability-card {
    .app-ability-card-value {
      flex: 0 0 auto;
      margin-left: auto;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid material.m2-get-color-from-palette($background, app-background-active);
      border-radius: 0.25rem;
    }

    h3 {
      margin-bottom: 0;
    }
  }
}
