@mixin app-content-license-links($theme) {
  .app-content-license-links {
    opacity: 0.5;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    a {
      text-decoration: none;
    }
  }
}
