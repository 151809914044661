@use 'sass:map';
@use '@angular/material';

@mixin app-input($theme) {
  $color: material.m2-get-color-config($theme);
  $foreground: map.get($color, foreground);
  $background: map.get($color, background);
  $primary: map.get($color, primary);
  $accent: map.get($color, accent);
  $warn: map.get($color, warn);

  .app-form-field {
    display: block;

    &.small-description-textarea textarea {
      min-height: 100px;
    }

    &.description-textarea textarea {
      min-height: 200px;
    }

    .mat-mdc-form-field {
      // hide indicator line
      --mdc-filled-text-field-focus-active-indicator-color: transparent;
      --mdc-filled-text-field-active-indicator-color: transparent;
      --mdc-filled-text-field-hover-active-indicator-color: transparent;
      --mdc-filled-text-field-disabled-active-indicator-color: transparent;
      --mdc-filled-text-field-error-active-indicator-color: transparent;
      --mdc-filled-text-field-error-focus-active-indicator-color: transparent;
      --mdc-filled-text-field-error-hover-active-indicator-color: transparent;

      // change color of floating label
      --mdc-filled-text-field-focus-label-text-color: rgba(85, 85, 85, 0.87);

      --mdc-filled-text-field-container-color: rgba(material.m2-get-color-from-palette($primary), 0.1);
      --mdc-filled-text-field-disabled-container-color: rgba(material.m2-get-color-from-palette($primary), 0.05);

      // border radius
      --mdc-filled-text-field-container-shape: 4px;

      // paddings for input with buttons
      .mat-mdc-form-field-infix:has(.mat-mdc-button-base) {
        --mat-form-field-container-vertical-padding: 8px;
      }

      width: 100%;

      .mdc-text-field--filled {
        --mdc-filled-text-field-container-shape: 1rem;
      }

      // Chagne container color to warn if field is invalid
      .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mat-mdc-form-field-focus-overlay {
        background-color: material.m2-get-color-from-palette($warn);
        opacity: 0.1;
      }

      // set flex for input parent
      .mat-mdc-form-field-infix {
        display: flex;
      }

      .mdc-text-field--filled {
        border-top-left-radius: var(--mdc-filled-text-field-container-shape);
        border-top-right-radius: var(--mdc-filled-text-field-container-shape);
        border-bottom-right-radius: var(--mdc-filled-text-field-container-shape);
        border-bottom-left-radius: var(--mdc-filled-text-field-container-shape);
      }

      // totally hide hint panel
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }

      .mat-mdc-text-field-wrapper {
        background-color: material.m2-get-color-from-palette($background, app-input-background);
      }
      // set default background the same as focused
      .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
      }
    }

    .mat-icon:not(.mat-accent) {
      color: material.m2-get-color-from-palette($foreground, 'secondary-text');
    }

    &.app-form-field-text-highlighted {
      .mat-primary {
        .mat-mdc-select-value,
        .mat-mdc-input-element {
          color: material.m2-get-color-from-palette($primary);
        }
      }
      .mat-accent {
        .mat-mdc-select-value,
        .mat-mdc-input-element {
          color: material.m2-get-color-from-palette($accent);
        }
      }
      .mat-warn {
        .mat-mdc-select-value,
        .mat-mdc-input-element {
          color: material.m2-get-color-from-palette($warn);
        }
      }
    }

    &.app-form-field-minified {
      --mat-form-field-container-height: 32px;
      --mat-form-field-container-vertical-padding: 4px;
      --mat-form-field-filled-with-label-container-padding-top: 4px;
      --mat-form-field-filled-with-label-container-padding-bottom: 4px;
      --mat-select-arrow-transform: 0;
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    &.app-form-field-dual {
      .mat-mdc-form-field {
        &.app-form-field-dual-invalid .mat-mdc-text-field-wrapper {
          background-color: rgba(material.m2-get-color-from-palette($warn), 0.1);
        }

        .app-form-field-dual-select {
          order: 1;
          margin-left: 0.5rem;
          flex: 2;
        }
        .app-form-field-dual-input {
          margin-right: 0.5rem;
          flex: 1;
          &:not([disabled]) {
            background-color: rgba(material.m2-get-color-from-palette($primary), 0.1);
            border-radius: 0.5rem;
            padding: 0.25rem 0.5rem;
          }
          &.ng-invalid.ng-touched {
            background-color: rgba(material.m2-get-color-from-palette($warn), 0.1);
          }
        }
      }
    }
  }
}
