@use 'sass:map';
@use '@angular/material';
@use 'src/scss/material/app-screen';

@mixin app-tile-card($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);
  $foreground: map.get($color, foreground);
  $typography: material.m2-get-typography-config($theme);

  .app-tile-card {
    @include material.elevation(2);
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: transparent;
    cursor: pointer;
    background-color: material.m2-get-color-from-palette($background, app-card-background);

    .app-tile-card-title {
      margin-top: 0.5rem;
      font-size: 4.6vw;
      word-wrap: break-word;
      @include app-screen.screen-md() {
        font-size: 3vw;
      }
      @include app-screen.screen-lg() {
        font-size: 2.3vw;
      }
      @include app-screen.screen-xxl() {
        @include material.m2-typography-level($typography, 'headline-4');
      }
      text-align: center;
      z-index: 1;
      position: relative;
      transition: color 0.3s ease-in-out;
    }

    .app-tile-card-background {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      color: inherit;
      transition: opacity 0.3s ease-in-out;
    }

    .app-tile-card-background-image {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: auto;
      top: 12%;
      left: 0;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    .app-tile-card-background-icon {
      position: absolute;
      z-index: 2;
      left: 25%;
      top: 25%;
      width: 50%;
      height: 50%;
      opacity: 0;
      transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out;
    }

    &:hover {
      color: material.m2-get-color-from-palette($foreground, app-text-contrast);
      .app-tile-card-title {
        z-index: 2;
      }
      .app-tile-card-background-image {
        opacity: 0.3;
      }
      .app-tile-card-background-icon {
        opacity: 1;
      }
      .app-tile-card-background {
        z-index: 0;
        opacity: 1;
      }
    }
  }
}
