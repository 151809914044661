@use 'sass:map';
@use '@angular/material';

@mixin app-line($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);

  .app-line {
    display: flex;
    align-items: center;
    padding: 0.25rem;

    > .mat-icon:first-child {
      margin-right: 0.25rem;
    }

    &.activatable {
      &:hover {
        background-color: material.m2-get-color-from-palette($background, app-hover);
        border-radius: 0.5rem;
        cursor: pointer;
      }
    }
  }
}
