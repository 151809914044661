@use 'sass:map';
@use '@angular/material';

@mixin app-table($theme) {
  $color: material.m2-get-color-config($theme);
  $foreground: map.get($color, foreground);
  $background: map.get($color, background);
  $typography: material.m2-get-typography-config($theme);

  @include material.table-density(-3);

  .app-table {
    display: block;
    overflow: auto;

    .mat-mdc-table {
      width: 100%;
      --mat-table-background-color: #{material.m2-get-color-from-palette($background, app-background)};

      .mat-mdc-row:hover {
        background-color: material.m2-get-color-from-palette($background, app-hover);
      }

      .mat-mdc-header-cell {
        @include material.m2-typography-level($typography, 'body-2');
        color: material.m2-get-color-from-palette($foreground, secondary-text);
      }

      .mat-mdc-header-cell,
      .mat-mdc-cell,
      .mat-mdc-footer-cell {
        border-bottom: none;
      }
    }
  }
}
