@mixin app-about($theme) {
  .app-about {
    color: #000000de;
    background-color: #ffffff;

    display: block;
    overflow: auto;
    height: 100vh;
    scroll-behavior: smooth;
    margin: 0;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: normal normal bold 12px/19px Roboto Flex;
    letter-spacing: 1.88px;
    text-transform: uppercase;

    font-size: 16px !important;
    line-height: 20px !important;

    @media (max-width: 767.99px) {
      font-size: 12px !important;
      line-height: 14px !important;
    }

    .app-about-page {
      z-index: 1;
      position: relative;
      display: block;
      min-height: 100vh;
    }

    .app-about-page-fullheight {
      display: flex;
      flex-direction: column;
      .app-about-page-container-fill {
        position: relative;
        min-height: 400px;
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .app-container {
      margin: 0 auto;
      max-width: min(1320px, 90%);

      @media (max-width: 767.99px) {
        max-width: calc(100% - 16px);
      }
    }

    .app-text-uppercase {
      text-transform: uppercase;
    }

    .app-font-regular {
      font-weight: 500;
      line-height: 14px;
    }

    .app-text-secondary {
      color: #00000086;
    }

    h1 {
      font-size: 47px;
      line-height: 55px;
      font-weight: bold;
      letter-spacing: 1.88px;

      @media (max-width: 767.99px) {
        font-size: 27px;
        line-height: 32px;
        margin: 0;
      }
    }

    p {
      margin: 0.75rem 0;
    }

    .app-site-name {
      display: flex;
      align-items: center;
      font-weight: 600;
      line-height: 14px;
      margin-bottom: 1.5rem;
      padding-top: 1.5rem;
      text-transform: uppercase;

      .app-site-name-dash {
        width: 30px;
        height: 5px;
        background-color: #000000;
        margin-right: 0.5rem;
      }
    }

    .app-header-bar {
      margin-top: 1rem;
      display: flex;
      align-items: center;

      @media (max-width: 767.99px) {
        flex-wrap: wrap;
      }

      .app-header-bar-title {
        margin: 0 auto;
        text-align: center;
      }

      .app-header-bar-item {
        text-align: right;
        width: 200px;

        @media (max-width: 767.99px) {
          &:first-of-type {
            width: 0;
          }
          &:last-of-type {
            width: 100%;
          }
        }
      }

      .app-header-bar-social {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        @media (max-width: 767.99px) {
          margin-top: 1rem;
          justify-content: center;
          align-items: center;
        }

        img {
          height: 32px;
          width: auto;
        }
      }
    }

    a {
      text-decoration: none;
      &,
      &:hover,
      &:visited,
      &:active {
        color: currentColor;
      }
    }

    .app-socials {
      display: flex;

      @media (max-width: 767.99px) {
        width: 100%;
        justify-content: space-around !important;
      }

      .app-social {
        display: block;
        height: 24px;
        align-self: center;
        margin-left: 8px;

        @media (max-width: 767.99px) {
          margin-left: 0;
        }

        img {
          height: 100%;
          width: auto;
        }
      }
    }

    .app-social-normalize-height {
      height: 32px !important;
    }

    .app-link-container {
      display: flex;
    }

    a.app-link {
      margin-left: auto;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      color: #f63c03;
      transition: background-color 300ms ease-in-out;

      &:hover {
        transition: background-color 100ms ease-in-out;
        background-color: rgba(#f63c03, 0.2);
      }
      &:active {
        transition: background-color 100ms ease-in-out;
        background-color: rgba(#f63c03, 0.35);
      }
    }
  }
}
