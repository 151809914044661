@use 'sass:map';
@use '@angular/material';

@mixin mat-button($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);
  $primary: map.get($color, primary);
  $accent: map.get($color, accent);
  $typography: material.m2-get-typography-config($theme);

  button {
    @include material.m2-typography-level($typography, 'button');
  }

  .mat-mdc-button-base {
    --mdc-typography-button-text-transform: uppercase;

    &.app-contrast-button {
      --mdc-protected-button-container-color: #{material.m2-get-color-from-palette(
          $background,
          app-special-button-container-color
        )};
      --mdc-filled-button-container-color: #{material.m2-get-color-from-palette(
          $background,
          app-special-button-container-color
        )};
      --mdc-protected-button-label-text-color: #{material.m2-get-color-from-palette(
          $background,
          app-special-button-text-color
        )};
      --mdc-filled-button-label-text-color: #{material.m2-get-color-from-palette(
          $background,
          app-special-button-text-color
        )};
    }

    // https://github.com/angular/components/issues/27118
    & .mat-mdc-button-touch-target {
      height: inherit !important;
    }

    .mdc-button__label {
      text-transform: uppercase;
    }

    // Set all icon based button to 40px width/height
    &.mat-mdc-icon-button {
      --mdc-icon-button-state-layer-size: 40px;
      padding: 8px;
    }

    &.mat-mdc-unelevated-button {
      --mdc-shape-small: 1rem;
    }

    /// Custom elements

    // Add opacity for icons inside button when disabled
    &[disabled] .mat-icon {
      opacity: 0.5;
    }

    // Big Button
    &.app-big-button {
      --mdc-text-button-container-height: 48px;
      --mdc-filled-button-container-height: 48px;
      --mdc-outlined-button-container-height: 48px;
      --mdc-protected-button-container-height: 48px;
    }

    // Toolbar Button
    &.app-toolbar-button:not(.mat-mdc-icon-button) {
      --mat-mdc-button-persistent-ripple-color: none;

      .mat-icon {
        font-size: 20px;
        width: 20px;
        height: 20px;
      }
      &.active {
        --mdc-protected-button-container-color: transparent;
        background-size: 100% 100%;
        background-position-y: center;
        background-repeat: no-repeat;
        background-color: material.m2-get-color-from-palette($accent);
        color: material.m2-get-contrast-color-from-palette($accent, 500);
      }
    }
    &.mat-mdc-icon-button.app-toolbar-button {
      border-radius: 0.25rem;
      .mat-mdc-button-persistent-ripple {
        border-radius: 0.25rem;
      }

      &.active {
        background-color: material.m2-get-color-from-palette($accent);
        color: material.m2-get-contrast-color-from-palette($accent, 500);
      }
    }

    &.app-profile-button {
      border-radius: 2rem;
      background: material.m2-get-color-from-palette($primary, 100);
      --mdc-text-button-label-text-color: #{material.m2-get-color-from-palette($primary, default-contrast)};
      &.active {
        background: material.m2-get-color-from-palette($accent, 500);
        --mdc-text-button-label-text-color: #{material.m2-get-color-from-palette($accent, default-contrast)};
      }
    }
  }
}
