@mixin app-reboot($theme) {
  .mat-h1,
  .mat-headline-5,
  .mat-typography .mat-h1,
  .mat-typography .mat-headline-5,
  .mat-typography h1 {
    text-transform: uppercase;
  }

  a {
    color: inherit;
  }
}
