@mixin screen-xs-only {
  @media (max-width: 575.99px) {
    @content;
  }
}

@mixin screen-sm {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin screen-md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin screen-lg {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin screen-lg-only {
  @media (min-width: 992px) and (max-width: 1199.99px) {
    @content;
  }
}

@mixin screen-xl {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin screen-xxl {
  @media (min-width: 1400px) {
    @content;
  }
}
