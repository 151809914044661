@use './scss';

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: 'Roboto + Flex', 'Helvetica Neue', sans-serif;
  color: rgba(0, 0, 0, 0.87);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// Input type number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.app-hide-scrollbar {
  *::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.app-show-scrollbar {
  *::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border: 1px solid rgb(0, 0, 0, 0.12);
    background: rgb(0, 0, 0, 0.05);
  }
  ::-webkit-scrollbar-track:vertical {
    border-top: none;
    border-bottom: none;
  }
  ::-webkit-scrollbar-track:horizontal {
    border-left: none;
    border-right: none;
  }

  ::-webkit-scrollbar-corner {
    background: rgb(0, 0, 0, 0.05);
    border-right: 1px solid rgb(0, 0, 0, 0.12);
    border-bottom: 1px solid rgb(0, 0, 0, 0.12);
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(142, 142, 142, 0.8);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(142, 142, 142);
  }
}
