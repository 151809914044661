@use 'sass:map';
@use '@angular/material';

@mixin app-placeholder($theme) {
  $color: material.m2-get-color-config($theme);
  $foreground: map.get($color, foreground);
  $typography: material.m2-get-typography-config($theme);

  .app-placeholder {
    position: relative;
    & > * {
      filter: blur(4px);
      opacity: 0.8;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &::after {
      @include material.m2-typography-level($typography, 'subtitle-2');
      text-transform: uppercase;
      color: material.m2-get-color-from-palette($foreground, 'secondary-text');
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      content: 'В разработке';
    }
  }
}
