@use 'sass:map';
@use '@angular/material';
@use 'src/scss/material/app-screen';

@mixin app-spell-card($theme) {
  .app-spell-card {
    .app-bg-hexagon {
      width: 48px;
      height: 48px;
    }

    .component-image {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .spell-card-grid {
      display: grid;
      grid-template-columns: 48px repeat(3, 1fr);
      grid-column-gap: 0.5rem;
      grid-row-gap: 0.5rem;

      .component-col {
        grid-column: 1 / 2;
        &.verbal-component {
          grid-row: 1 / 2;
        }
        &.somatic-component {
          grid-row: 2 / 3;
        }
        &.material-component {
          grid-row: 3 / 4;
        }
      }

      .reagents-col {
        grid-column: 2 / 5;
      }

      .subcard-col {
        &.duration-col {
          grid-column: 3 / 5;
        }
        @include app-screen.screen-xs-only() {
          &,
          &.duration-col {
            grid-column: 2 / 5;
          }
        }
      }
    }
  }

  &.app-dark-theme .app-spell-card .base-bg-color {
    background-color: rgba(255, 255, 255, 0.16);
  }
  &:not(.app-dark-theme) .app-spell-card .base-bg-color {
    background-color: rgba(0, 0, 0, 0.16);
  }
}
