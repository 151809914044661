@use 'sass:map';
@use '@angular/material';
@use 'src/scss/material/app-screen';

@mixin mat-snackbar($theme) {
  @include app-screen.screen-xxl() {
    .mat-mdc-snack-bar-container {
      transform: scale(1.2) translateY(-0.5rem) !important;
    }
  }
}
