@use 'sass:map';
@use '@angular/material';

@mixin app-highlighted-span($theme) {
  $color: material.m2-get-color-config($theme);
  $background: map.get($color, background);

  .app-highlighted-span {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1.25rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    height: 1.25rem;
    background-color: material.m2-get-color-from-palette($background, app-highlighted-span);
    border-radius: 0.25rem;
    font-weight: bold;
    font-size: 12px;
  }
}
