@use 'sass:map';
@use '@angular/material';

@mixin app-dialog($theme) {
  .app-dialog {
    .mat-mdc-dialog-container {
      --mdc-dialog-container-shape: 1rem;
    }

    .mat-bottom-sheet-container {
      padding: 0;
      background: transparent;
      > * {
        display: block;
        height: 100%;
        > .app-card {
          height: 100%;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .app-no-bottom-sheet-border-bottom-radius {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .app-overlay-backdrop {
    backdrop-filter: blur(4px);
  }
}
