@use 'sass:map';
@use '@angular/material';

@mixin app-floating-button($theme) {
  .app-floating-buttons {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: column;

    > * {
      margin-top: 0.5rem;
    }
  }

  .app-floating-button {
    position: fixed !important;
    bottom: 1rem;
    right: 1rem;
  }
}
